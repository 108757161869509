@use '@/styles/variables/fonts' as *;

@layer reset {
  // Default font styles:
  body,
  button {
    font-family: $body-font;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $heading-font;
  }
}
