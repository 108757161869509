////
/// This the global stylesheet. This file should be used very
/// sparingly, as it is included indiscriminately on all pages,
/// whereas using SCSS modules allows our styles to be "tree-shaken"
/// on a per-component basis. Only include in here what is necessary
/// across all pages, globally. If you can tie your styles directly
/// to a component – do that instead.
/// @group global
////

@import "core";
@import "variables";
@import "utils";
@import "third-party/osano";

strong,
 b {
    font-weight: $font-weight-bold;
}
