////
/// Defines the CSS "reset" styles, for normalizing browser behavior.
/// @group base/reset
////

@use "./normalize.css";

@layer reset {
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}