@use "@/styles/variables" as *;

// Osano Consent Manager Variables
.osano-cm-dialog {
  font-family: $body-font;
  font-size: $font-size-base;
  display: none;
}

.osano-cm-dialog,
.osano-cm-button {
  background-color: $color-primary-700;
}

.osano-cm-button {
  border-radius: unset;
  font-family: $body-font;
  font-weight: $font-weight-base;
  color: $color-white;
  border: 1px solid $color-white;
  text-transform: uppercase;
  font-size: $font-size-base;

  &:hover {
      background-color: $color-primary-700;
  }
}

.osano-cm-button--type_deny {
  background-color: $color-white;
  color: $color-primary-700;
  border: 1px solid $color-primary-700;

  &:hover {
      background-color: $color-white;
  }
}

.osano-cm-link {
  color: $color-white;
  text-decoration: underline;
}

.osano-cm-window__widget {
  display: none;
}
