////
/// Defines the fonts-faces to be included on the site globally.
/// @group base/fonts
////

// Note on different formats:
// Browsers try to load font files in order they're declared in CSS.
// WOFF2 format emerged as the winner between competing formats for web fonts.
// It is supported by most browsers, offers the most functionality and has the best
// compression. For these reasons, declare the WOFF2 src first, followed by
// other formats.
// References:
//   - https://developer.mozilla.org/en-US/docs/Learn/CSS/Styling_text/Web_fonts
//   - https://caniuse.com/?search=woff2

@forward "@/base/styles/core/fonts";

// Font face definitions:

// Haas Grotesk Display Web: XThin
@font-face {
  font-family: "Haas Grotesk Display Web";
  src: url("/fonts/NeueHaasGroteskDisplay-25XThin-Web.woff2") format("woff2"),
    url("/fonts/NeueHaasGroteskDisplay-25XThin-Web.woff") format("woff");
  font-weight: 50;
  font-style: normal;
}

// Haas Grotesk Display Web: Thin
@font-face {
  font-family: "Haas Grotesk Display Web";
  src: url("/fonts/NeueHaasGroteskDisplay-35Thin-Web.woff2") format("woff2"),
    url("/fonts/NeueHaasGroteskDisplay-35Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

// Haas Grotesk Display Web: Light
@font-face {
  font-family: "Haas Grotesk Display Web";
  src: url("/fonts/NeueHaasGroteskDisplay-45Light-Web.woff2") format("woff2"),
    url("/fonts/NeueHaasGroteskDisplay-45Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

// Haas Grotesk Display Web: Roman
@font-face {
  font-family: "Haas Grotesk Display Web";
  src: url("/fonts/NeueHaasGroteskDisplay-55Roman-Web.woff2") format("woff2"),
    url("/fonts/NeueHaasGroteskDisplay-55Roman-Web.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// Haas Grotesk Display Web: Italic
@font-face {
  font-family: "Haas Grotesk Display Web";
  src: url("/fonts/NeueHaasGroteskDisplay-56Italic-Web.woff2") format("woff2"),
    url("/fonts/NeueHaasGroteskDisplay-56Italic-Web.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

// Haas Grotesk Display Web: Medium
@font-face {
  font-family: "Haas Grotesk Display Web";
  src: url("/fonts/NeueHaasGroteskDisplay-65Medium-Web.woff2") format("woff2"),
    url("/fonts/NeueHaasGroteskDisplay-65Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

// Haas Grotesk Display Web: Medium Italic
@font-face {
  font-family: "Haas Grotesk Display Web";
  src: url("/fonts/NeueHaasGroteskDisplay-66MediumItalic-Web.woff2")
      format("woff2"),
    url("/fonts/NeueHaasGroteskDisplay-66MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
